async function postMessageService(message, userId) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/sendMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        id: userId,
      }),
    }
  );
  const data = await response.json();
  return data;
}

export default postMessageService;
