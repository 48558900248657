import {Box, Image, Text} from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
      <Text color={"white"}>
        Built with 🧡 by
      </Text>
      <Box width={"35%"} marginLeft={"0.5em"}>
        <a href="https://www.flamatech.io" style={{color: "#FFF", textDecoration: "none"}}><Image src={"/logo.svg"} alt={"logo"}/></a>
      </Box>
    </Box>
  );
};

export default Footer;
