import {Box} from "@chakra-ui/react";
import "../styles.css"
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import {useEffect, useState} from "react";
import validateService from "../../services/validateService";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import AdminsForm from "../../components/manageAdmins/AdminsForm";
import RequestAccess from "../../components/manageAdmins/RequestAccess";

function ManageAdmins() {
  const [data, setData] = useState(undefined);
  const navigate = useNavigate();
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.BackButton.show();
  const tg = window.Telegram.WebApp;

  useEffect(() => {
    (async () => {
      const isValid = await validateService(window.Telegram.WebApp.initData);
      if(!isValid.value) navigate("/not-allowed");

      setData({
        user: JSON.parse(isValid.user),
        value: isValid.value,
        initData: window.Telegram.WebApp.initData,
      });

    })();
  }, [navigate]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(()=>{
      navigate("/main")
    })
  }, [navigate]);

  if(data === undefined) {
    return (
      <Box className="App-header" justifyContent={"center"}>
        <LoadingSpinner size={"xl"}/>
      </Box>
    )
  }

  return (
    <Box className="App-header">
      <Header userData={data.user}/>
      {data.user.id === 11697044 ? <AdminsForm userData={data.user} tg={tg}/> : <RequestAccess userData={data.user}/>}
      <Footer/>
    </Box>
  );
}

export default ManageAdmins;