
async function getAvatarService(data) {
  const avatarPath = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getProfilePic?user_id=${data.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const avatar = await avatarPath.json();
  return avatar.avatar;
}

export default getAvatarService;
