import {Box, Flex, Text, useCheckbox, chakra} from "@chakra-ui/react";

const CustomCheckBox = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props)

  return (
    <chakra.label
      display='flex'
      flexDirection='row'
      alignItems='center'
      gridColumnGap={2}
      cursor='pointer'
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems='center'
        justifyContent='center'
        border='2px solid'
        borderColor='orange.500'
        w={4}
        h={4}
        {...getCheckboxProps()}
      >
        {state.isChecked && <Box w={2} h={2} bg='orange.500' />}
      </Flex>
      <Text marginY="0.25em" color="white" {...getLabelProps()}>{props.label}</Text>
    </chakra.label>
  );
};

export default CustomCheckBox;
