async function getUserInfoService(userId) {
  const userInfo = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getUser?user_id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const info = await userInfo.json();
  return info;
}

export default getUserInfoService;
