import "../styles.css"
import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea, useDisclosure
} from "@chakra-ui/react";
import makeAnimated from "react-select/animated";
import {useNavigate} from "react-router-dom";
import validateService from "../../services/validateService";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import ShareModal from "../../components/shareMessage/ShareModal";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import { Select } from "chakra-react-select";
import getChannelsListService from "../../services/getChannelsList";
import getWhitelistedService from "../../services/getWhitelistedService";

const maxLengthText = 1600;
const animatedComponents = makeAnimated();

const ShareMessage = () => {
  // ---------  FORM VARIABLES ------------
  const [formState, setFormState] = useState({
    textInput: "",
    selectedOptions: [],
  });
  const [options, setOptions] = useState([]);
  const [data, setData] = useState(undefined);
  const navigate = useNavigate();

  // ---------  MODAL VARIABLES ------------
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  // ---------  TELEGRAM API ------------
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.BackButton.show();
  const tg = window.Telegram.WebApp;


  // ---------  LOADING DATA ------------
  useEffect(() => {
    (async () => {
      const isValid = await validateService(window.Telegram.WebApp.initData);
      if(!isValid.value) navigate("/not-allowed");

      setData({
        user: JSON.parse(isValid.user),
        value: isValid.value,
        initData: window.Telegram.WebApp.initData,
      });

    })();
  }, [navigate]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(()=>{
      navigate("/main")
    })
  }, [navigate]);

  useEffect(() =>{
    if (data === undefined) return;
    (async () => {
      const isWhitelisted = await getWhitelistedService(data.user.id);
      if (!isWhitelisted) navigate("/manage-admins");
      const channelList = await getChannelsListService(data);
      setOptions(channelList);
    })();
  }, [data])

  // ---------  HANDLERS ------------
  const handleInput = (event) => {
    const value = event.target.value;
    setFormState({
      ...formState,
      textInput: value,
    });
  };

  const handleAddAllChannels = () => {
    setFormState({
      ...formState,
      selectedOptions: options,
    });
  }

  const handleSelect = (selectedOptions) => {
    setFormState({
      ...formState,
      selectedOptions: selectedOptions,
    });
  };

  // ---------  FORM STATE ------------
  if(data === undefined) {
    return (
      <Box className="App-header" justifyContent={"center"}>
        <LoadingSpinner size={"xl"}/>
      </Box>
    )
  }

  return (
    <Box className="App-header">
      <Header userData={data.user} />
        <FormControl>
          <Box marginX={"1em"}>
            <FormLabel>Write the message to share!</FormLabel>
            <Textarea
              className={"textArea"}
              maxLength={maxLengthText}
              onChange={handleInput}
              value={formState.textInput}
              placeholder="Your message"
            />
            <FormHelperText textAlign={"right"}>
              {formState.textInput.length}/{maxLengthText}
            </FormHelperText>
            <Box display={"flex"} justifyContent={"space-between"}>
              <FormLabel>Share on:</FormLabel>
              <FormLabel color={formState.selectedOptions.length ? "orange" : "white"}>{formState.selectedOptions.length} Channels Selected</FormLabel>
            </Box>
            <Select
              isMulti
              name="channels"
              options={options}
              tagVariant={"outline"}
              components={animatedComponents}
              useBasicStyles
              onChange={(selectedOption) =>
                handleSelect(selectedOption)
              }
              className="chakra-react-select"
              classNamePrefix="chakra-react-select"
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginX={"1em"}
            marginY={"1em"}
          >
            <Button colorScheme={"orange"} onClick={handleAddAllChannels} width={"32%"}>
              All Channels
            </Button>
            <Button colorScheme={"green"} onClick={onOpenShare} width={"48%"}>
              Share
            </Button>
          </Box>
        </FormControl>

      <ShareModal
        isOpen={isOpenShare}
        onClose={onCloseShare}
        formState={formState}
        data={data.initData}
        tg={tg}
      />
      <Footer/>
    </Box>
  );
};


export default ShareMessage;
