async function postDelAdminsService(admins, userId) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/delAdmins`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admins: admins,
        id: userId,
      }),
    }
  );
  const data = await response.json();
  return data;
}

export default postDelAdminsService;
