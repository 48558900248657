import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useCheckboxGroup
} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";
import LoadingSpinner from "../shared/LoadingSpinner";
import {useNavigate} from "react-router-dom";
import getAdminsList from "../../services/getAdminsList";
import CustomCheckBox from "./CheckBoxGroup";
import CheckBox from "./CheckBox";
import postAddAdminsService from "../../services/postAddAdminsService";
import postDelAdminsService from "../../services/postDelAdminsService";

const AdminsForm = (props) => {
  const [adminSearch, setAdminSearch] = useState("");
  const [admins, setAdmins] = useState(undefined);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [adminsChecked, setAdminsChecked] = useState(undefined);

  const navigate = useNavigate();

  const handleSave = async () => {
    const adminsToAdd = selectedAdmins.filter((admin) => !adminsChecked.includes(admin));
    const adminsToRemove = adminsChecked.filter((admin) => !selectedAdmins.includes(admin));

    if(adminsToAdd.length > 0){
      await postAddAdminsService(adminsToAdd, props.userData.id)
    }
    if(adminsToRemove.length > 0){
      await postDelAdminsService(adminsToRemove, props.userData.id)
    }
    setAdminsChecked(selectedAdmins)
    props.tg.showAlert("Saved! 🚀")
  }

  useEffect(() => {
    (async () => {
      const list = await getAdminsList(props.userData.id, adminSearch);
      const checked = [];
      list.admins.forEach(({ userId, canShare }) => {
        if(canShare) {
          checked.push(userId);
        }
      });
      setAdminsChecked(checked)
      setAdmins(list.admins);
    })();
  }, [props.userData.id, adminSearch]);

  if(!props.userData || admins === undefined || adminsChecked === undefined){
    return (<LoadingSpinner size={"xl"}/>)
  }

  if(props.userData.id !== 11697044){
    navigate("/main")
  }



  return (
    <Box width={"80%"} display={"flex"} flexDirection={"column"} justifyContent={"start"}>
      <FormControl>
        <FormLabel>
          Add admins to use the bot
        </FormLabel>
        <Input
          type="text"
          placeholder="Admin name/@username"
          value={adminSearch}
          onChange={(e) => setAdminSearch(e.target.value)}
          width={"90%"}
          color={"white"}
          marginBottom={"1em"}
        />
        <Box maxHeight={"400px"} overflowY={"scroll"} overflowX={"hidden"}>
          {admins
            ? ( <CheckBox admins={admins} adminsChecked={adminsChecked} setAdminsChecked={setSelectedAdmins}/> )
            : ( <LoadingSpinner size={"xl"}/> )
          }
        </Box>

        <Box width={"100%"} display={"flex"} flexDirection={"row-reverse"} marginTop={"1em"}>
          <Button width={"35%"} colorScheme={"green"} variant={"solid"} onClick={(e)=> {
            e.preventDefault();
            handleSave();
          }}>
            Save
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default AdminsForm;
