import {Box, Button, Divider, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import getUserInfoService from "../../services/getUserInfoService";
import LoadingSpinner from "../shared/LoadingSpinner";
import postMessageService from "../../services/postMessageService";

const RequestAccess = ({userData}) => {
  const [userInfo, setUserInfo] = useState(undefined);

  useEffect(() => {
    (async () => {
      const response = await getUserInfoService(userData.id);
      setUserInfo(response.user);
    })();
  }, [userData]);

  if(userInfo === undefined) return (
    <LoadingSpinner size={"xl"}/>
  );

  const handleButtonClick = async (e) => {
    e.preventDefault();
    const result = await postMessageService(
      `User ${userInfo.username ? `@${userInfo.username}` : userInfo.name} [uid: ${userInfo.userId}] wants to use the bot 🚀`,
      "11697044")
    if(result.data.ok){
      window.Telegram.WebApp.showAlert("Request sent! 🚀")
    }
  }

  return (
    <Box width={"90%"}>
      {userInfo.canShare ? (
        <Box width={"90%"}>
          <Text as="b" fontSize={"xl"}>
            You can use the bot! 🚀
          </Text>
          <Divider/>
          <Text>
            Remember to add the bot in your groups and send the command /refresh
          </Text>
          <Divider/>
          <Text>
            🏆 Bot is deployed in {userInfo.isAdminOn.length} groups
          </Text>
        </Box>
          )
        : userInfo.isAdminOn.length > 0 ? (
            <Box>
              <Text as="b" fontSize={"xl"}>
                Do you want to use the bot?
              </Text>
              <Text>
                Click the button below for request access!
              </Text>
              <Box display="flex" justifyContent={"center"}>
                <Button colorScheme={"orange"} variant={"outline"} onClick={(e) => handleButtonClick(e)}> Request access! </Button>
              </Box>
            </Box>)
          : (
            <Box>
              <Text as="b" fontSize={"xl"}>
                You are NOT an admin in any group.
              </Text>
              <Text>
                You need add the bot in your groups and send the command /refresh, after that you can request access.
              </Text>
            </Box>
          )
      }
    </Box>
  );
};

export default RequestAccess;
