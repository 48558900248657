import {Box, Spinner} from "@chakra-ui/react";

const LoadingSpinner = ({size}) => {
  return (
    <Box>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='orange.500'
        size={size ? size : "xl"}
      />
    </Box>
  );
};

export default LoadingSpinner;
