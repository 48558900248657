import './App.css';
import {useEffect} from "react";
import {Box, Button} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Footer from "./components/shared/Footer";

function App() {
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      window.Telegram.WebApp.ready();
      const initData = window.Telegram.WebApp.initData
      if (!initData) navigate("/not-allowed");
    })();
  }, [navigate]);

  function handleClick (e) {
    e.preventDefault();
    navigate("/main")
  }

  return (
    <Box>
      <Box className="App-header">
        <Box>
          <h2>Telegram Forwarder</h2>
        </Box>
        <Box width={"100%"} display="flex" flexDirection="column" justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
          <Button
            colorScheme={"orange"}
            variant={"outline"}
            width={"65%"}
            onClick={(e) => handleClick(e)}>Start!</Button>
        </Box>
        <Footer/>
      </Box>
    </Box>
  );
}

export default App;
