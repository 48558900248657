import {useEffect, useState} from "react";
import {Box} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import "../styles.css"
import Header from "../../components/shared/Header";
import validateService from "../../services/validateService";
import Menu from "../../components/Landing/Menu";
import Footer from "../../components/shared/Footer";
import LoadingSpinner from "../../components/shared/LoadingSpinner";

function Landing() {
  let navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.BackButton.show();

      const isValid = await validateService(window.Telegram.WebApp.initData);

      if(!isValid.value) navigate("/not-allowed");
      setData({
        user: JSON.parse(isValid.user),
        value: isValid.value,
        initData: window.Telegram.WebApp.initData,
      });
    })();
  }, [navigate]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(()=>{
      navigate("/")
    })
  }, [navigate]);

  if(!data) {
    return (
      <Box className="App-header" justifyContent={"center"}>
        <LoadingSpinner size={"xl"}/>
      </Box>
    )
  }

  return (
    <Box className="App-header">
      <Header userData={data.user}/>
      <Menu/>
      <Footer/>
    </Box>
  );
}

export default Landing;