async function getAdminsList(userId, adminSearch) {
  const adminsList = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getAdmins?user_id=${userId}&name=${adminSearch}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const list = await adminsList.json();
  return list;
}

export default getAdminsList;
