import {Box, Link, Image} from "@chakra-ui/react";
import "../styles.css"

function NotAllowed() {
  const BOT_USERNAME = process.env.REACT_APP_BOT_USERNAME;

  return (
    <Box className="App-header">
      <Box
        width={"100%"}
        height={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Link href={"https://www.flamatech.io"}>
          <Image src={"/logo.svg"} width={"10em"} alt={"logo"} style={{marginBottom: "2em"}}/>
        </Link>
        <Box marginX={"2em"}>
          <h5> Sorry, this app is not allowed to be used from browser, join to <Link href={`https://t.me/${BOT_USERNAME}`}>@{BOT_USERNAME}</Link> </h5>
        </Box>
      </Box>
    </Box>
  );
}

export default NotAllowed;