async function getWhitelistedService(userId) {
  const userInfo = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/whitelisted?user_id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return (await userInfo.json()).whitelisted;
}

export default getWhitelistedService;
